<template>
  <div>
    <v-row justify="space-between">
      <div>
        <h4 class="subtitle-2 white--text">{{ group.label }}</h4>
        <h3 class="title white--text">{{ displayTitle }}</h3>
      </div>

      <div>
        <g-button label="Return" @onclick="$router.push({ name: 'admin_textconfig' })" />
        <save-button :onsave="saveItem" />
      </div>
    </v-row>

    <a-card>
      <v-row>
        <v-col cols="5">
          <a-select label="Environment" v-model="selectedEnv" :items="ENV" @change="(v) => setText(v)" />
        </v-col>
      </v-row>
      <RichTextEditor v-if="config.inputType === 'RICHTEXT'" v-model="editText" />
      <f-textarea v-else v-model="editText" />

      <div v-html="editText" class="ma-5" :class="customCss"></div>
    </a-card>
  </div>
</template>

<script>
import TEXTCONFIG from "@/config/TEXTCONFIG_CONFIG";
import ENV from "@/config/ENV";
export default {
  data() {
    return {
      ENV,
      selectedEnv: "USA",
      editText: null,
    };
  },
  computed: {
    customCss() {
      let c = {
        TECHNICAL_WARN_HEADER: "red--text",
      };
      return c[this.$route.params.refkey];
    },
    group() {
      return TEXTCONFIG[this.$route.params.groupkey] || {};
    },
    config() {
      let config = this.group.content;

      return config[this.$route.params.refkey] || {};
    },
    displayTitle() {
      return this.config.label;
    },
  },
  methods: {
    setText(env) {
      this.$store
        .dispatch("TextConfigStore/getOneTextDefault", {
          env,
          refkey: this.$route.params.refkey,
        })
        .then((data) => {
          this.editText = data && data.text;
        })
        .catch((err) => this.$root.$error(err));
    },
    saveItem() {
      return this.$store.dispatch("TextConfigStore/updateTextDefaultConfiguration", {
        curenv: this.$store.getters["AccountStore/environment"],
        env: this.selectedEnv,
        refkey: this.$route.params.refkey,
        text: this.editText,
      });
    },
  },
  mounted() {
    this.setText(this.selectedEnv);
  },
};
</script>
